/*INDEX THEME
	
	- BASE
    - LAYOUT
    - COMPONENTS
	- PAGES
    - PLUGINS 
	- MEDIAQUERIES
    - OLDIES

************************************************************************************/

/* 
=======================
PROJECT STYLES
======================= 
*/

/*BASE*/
@import 'base/colors';
@import 'base/variables';
@import 'base/mixins';
@import 'base/typography';
@import 'base/animations';

/*LAYOUT*/
@import 'layout/grid';

/*COMPONENTS*/
@import 'components/commons';
@import 'components/header';
@import 'components/footer';
@import 'components/tables';
@import 'components/forms';
@import 'components/navigation';
@import 'components/buttons';
@import 'components/modals';
@import 'components/pager';

/*PAGES*/
@import 'pages/home';
@import 'pages/products';
@import 'pages/cart';
@import 'pages/access';
@import 'pages/error-pages';

/*PLUGINS*/
@import 'plugins/featherlight/featherlight.gallery';
@import 'plugins/sweetalert/sweetalert';

/*MEDIAQUERIES*/
@import 'mediaqueries/tweakpoints';

/*OLDIES*/
@import 'oldies/modernizr';
@import 'oldies/hacks';

/******* IF edition out of SCSS System is needed, please use "auxiliar.css" file in /css directory **************/

/* 
=======================
PROJECT STYLES
======================= 
*/







