/*NO FLEXBOX*/
.no-flexbox{
	.flex-content{
		@include no-flexbox-flex-container;
	}
    .row {
        width: 100%;
        display: block;
        clear: both;
        vertical-align: top;
        padding: 5px 0;
    }
    .one-third-column {
        width: 33%;
        display: inline-block;
        vertical-align: top;

        & + .two-third-column {
            width: 66%;
            display: inline-block;
            float: right;
        }
    }
    .two-third-column {
        width: 66%;
        display: inline-block;
        vertical-align: top;

        & + .one-third-column {
            width: 33%;
            display: inline-block;
            float: right;
        }
    }
    .one-fourth-column {
        width: 22%;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            float: right;
        }
    }
    .three-fourth-column {
        width: 75%;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            float: right;
        }
    }
    .half-column {
        width: 47%;
        display: inline-block;
        vertical-align: top;

        & + .half-column {
            float: right;
        }
    }
    .paginator{
        display:block;

        li{

            &.previous{
                display: inline-block;
                vertical-align: top;
                width: 30%;
            }
            &.current-page{
                display: inline-block;
                vertical-align: top;
                width: 35%;
            }
            &.next{
                display: inline-block;
                vertical-align: top;
                width: 30%;
                float: right;
            }
        }
    }
}

/*NO GRID*/
.no-cssgrid{

}

/*NO OBJECT FIT*/
.no-object-fit{
}