%font-family-main{
    font-family: 'Source Sans Pro', sans-serif;
}
%font-family-secondary{
    font-family: 'Source Sans Pro', sans-serf;
}

/*Font Variables*/
    $regular: 	400;
    $bold: 		700;
    $light: 	300;

/*FONT SIZE*/
html{
    font-size: 62.5%;
}
h1 {
    @include font-size(2.6);
    font-weight: $regular;
    margin: 0 0 20px 0;
}
h2 {
    @include font-size(2.2);
    font-weight: $regular;
    margin: 0 0 20px 0;
}
h3 {
    @include font-size(2);
    margin: 0 0 15px 0;
}
h4 {
    @include font-size(1.8);
    font-weight: $regular;
    margin: 0 0 15px 0;
}
h5 {
    @include font-size(1.8);
    margin: 0 0 15px 0;
}
h6 {
    @include font-size(1.6);
    font-weight: $regular;
    margin: 0 0 15px 0;
}
p, li {
    @include font-size(1.6);
}
p {
    margin: 0 0 10px 0;
}
li {
    margin: 0 0 10px 0;
}
