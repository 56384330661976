/********************************************************************************* PAGER */

/*  index

    ////// COMMON STYLES
*/


/*
    ////// COMMON STYLES
*/
.pager{
    @include flex-container;
    height: auto;

    li{
        margin: 0;
        @include font-size(1.6);
        font-weight: $bold;
    }
    .previous, .next{
        a{
            display: block;
            width: 30px;
            height: 30px;
        }
    }
    .previous{
        a{
            background: url("../img/arrow-pager-left.svg") no-repeat center center;
            display: block;
            width: 30px;
            height: 30px;
        }
    }
    .next{
        a{
            background: url("../img/arrow-pager-right.svg") no-repeat center center;
            display: block;
            width: 30px;
            height: 30px;
        }
    }
    .page-list{
        text-align: center;

        span{
            padding: 0 10px;
        }
    }
    .page-selector{
        a{
            @include color-link($color-main);
        }
        &.current-page{
            a{
                @include color-link($color-secondary);
            }
        }
    }
}
.hidden-next-previous{
    visibility: hidden;
}
