/* thanks to http://codepen.io/geoffyuen/pen/FCBEg --> this bite of code is copied from this URL, but slightly customized. */

/*Table Layout*/
.rwd-table {
  margin: 10px 0;
  min-width: 300px;
  width: 100%;
  
  th {
    display: none;
  }
  
  td {
    display: block; 
    

    &:before {
      content: attr(data-th)": "; 
      display: block;
      
      @media (min-width: 600px) {
        display: none;
      }
    }
  }  
  th, td {
    
    @media (min-width: 600px) {
      display: table-cell;
      padding: 4px;
      
      &:last-child {
        padding-right: 0;
      }
    }

  }
}

/*Table Style*/
.rwd-table {
  @include font-size(1.6);
  
  tr {
    border-bottom: 1px solid $color-main;
      
    &:hover {
    	background:lighten($color-green,16%);
    }
  }
  
  th {
      font-weight: bold;   
      background: $color-table-header;
      text-align: left;
      
      &:last-child{
          float: right;
      }
  }
  
  td {
    
//    &:first-child {
//      padding-top: 9px;
//    }
//    &:last-child {
//      padding-bottom: 9px;
//    }
      &:last-child{
          float: right;
      }
    a{
        border: none;
	    font-weight: bold;
	    @include font-size(1.6);
	    
	    &:hover {
	    	cursor: pointer;
	    }
    }

    &:before {
      font-weight: bold;
      width: auto; 
    }
  }  
}

/*Plain table*/
.main-content{
    table{
        width: 100%;
        margin: 30px 0;
        
        caption{
            font-weight: $bold;
            padding: 10px 0;
        }
    }
    td{
        border: 1px solid $color-main;
        padding: 5px;
        
        @media screen and (max-width: 800px){
            white-space: nowrap;
        }
    }
    .table-wrapper{
        @media screen and (max-width: 800px){
            width: 100%;
            overflow-x: auto;
        }
    }
}