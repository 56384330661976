/********************************************************************************* MIXINS */

/*  index

    ////// FONT MIXINS
    ////// BUTTONS
    ////// FLEX
    ////// OTHER MIXINS
    ////// RESPONSIVE
    ////// FUNCTIONS
*/

/*
    ////// FONT MIXINS
*/

@mixin font-size($sizeValue) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
    line-height: ($sizeValue * 12.5) + px;
    line-height: ($sizeValue * 1.5) + rem;
}

/*
    ////// BUTTONS
*/
@mixin main-button($colorMainButton, $colorMainButtonText, $colorMainButtonTextHover){
    display: block;
    background: $colorMainButton;
    padding: 10px 30px;
    color: $colorMainButtonText;
    text-align: center;
    border: none;
    border-radius: 4px;
    width: auto;
    margin: 10px 0;

    &:hover, &:active{
        background: darken($colorMainButton,10%);
        color: $colorMainButtonTextHover;
    }
    &:link{
        color: white;
    }
}


/*
    ////// FLEX
*/
@mixin flex-container{
    width: 100%;
    display: -webkit-box; /*iOS6, Safari 3.1-6*/
    display: -ms-flexbox;
    display: -webkit-flex; /*android 4.3, IE mobile, Safari*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items:stretch;
    align-content: stretch;
    justify-content: space-between;
    height: 100%;
}
@mixin flex-flexible-element{
    flex: 1 0;
    min-width: 0;
}
@mixin flex-fixed-element($width){
    flex: 0 0 $width;
    min-width: 0;
}
@mixin no-flexbox-flex-container{
    display: table!important;
    width: 100%;
}
@mixin no-flexbox-flex-container-elements{
    display: table-cell!important;
    vertical-align: top;
}


/*
    ////// OTHER MIXINS
*/
@mixin block-element{
	width: 100%;
	display: block;
	clear: both;
	float: none;
}

@mixin alpha($color, $opacity: 0.3) {
    background: $color; 
    background: rgba($color, $opacity);
}
@mixin column($column-number, $column-width){
	-webkit-columns: $column-number $column-width;
	     -moz-columns: $column-number $column-width;
	          columns: $column-number $column-width;
}
@mixin color-link($color) {
    color: $color; 
    
    &:link, &:visited, &:active{
        color: $color; 
    }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/*
    ////// RESPONSIVE
*/
@mixin bp($point) {

    $bp-minimobile: "(max-width: 350px)";
    $bp-mobile: "(max-width: 700px)";
    $bp-tablet: "(max-width: 900px)";
    $bp-desktop: "(max-width: 1200px)";
    $bp-superdesktop: "(min-width: 1201px)";

    @if $point == superdesktop {
        @media #{$bp-superdesktop} { @content; }
    }
    @else if $point == desktop {
        @media #{$bp-desktop} { @content; }
    }
    @else if $point == tablet {
        @media #{$bp-tablet}  { @content; }
    }
    @else if $point == mobile {
        @media #{$bp-mobile}  { @content; }
    }
    @else if $point == minimobile {
        @media #{$bp-minimobile}  { @content; }
    }
}


/*
    ////// FUNCTIONS

/*@each*/
$list: 1 2 3 4 5 6 7 8 9 10;

@each $ranking-position in $list{
    .school-ranking {
        .school-ranking-list {
            li {
                &:nth-child(#{$ranking-position}):after{
                    content:"#{$ranking-position}";
                }
            }
        }
    }
}