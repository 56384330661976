/********************************************************************************* IE's */
/*Internet Explorer 10*/
//html[data-useragent*='MSIE 10.0'] .three-column{
//    .column{
//    	&.middle{
//    		margin: 0 25px;
//    	}
//    }
//}         
//html[data-useragent*='MSIE 10.0'] .bottom-home .bottom-home-right .bottom-video-bg video, 
//html[data-useragent*='Windows NT 6.1'] .bottom-home .bottom-home-right .bottom-video-bg video,
//html[data-useragent*='Windows NT 10.0'] .bottom-home .bottom-home-right .bottom-video-bg video{
//	border-radius: 100%;
//}
/*end Internet Explorer 10*/

//@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
//}

/*IE11*/
//html[data-useragent="Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko"]{
    /* styles go here */  
//}
/*end IE11*/

/*Edge*/
//@supports (-ms-ime-align:auto)
//and (-webkit-text-stroke:initial) {
//  .main-content-header{
//    }
//}
/*end Edge*/

/*hack IE 11 for object fit in slide image*/
//@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //.main-content-header{
    //    }
//}


/*HACK SAFARI 9 - for display grid not working there*/
//@supports (overflow:-webkit-marquee)
//   and (justify-content:inherit) {
//        .highlighted-courses{
//            .brcm-pagina-region{
//                @include flex-container;
//                justify-content: flex-start;
//                
//                .brcm-pagina-element{
//                    @include flex-fixed-element(31%);
//                    margin-right: 10px;
//                }
//            }
//        }
//}
/*end HACK SAFARI 9 - for display grid not working there*/