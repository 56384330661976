/********************************************************************************* MENUS */

/*  index

    ////// GLOBALS
    ////// MAIN MENU
    ////// FOOTER MENU
    ////// SECTIONS MENU
    ////// COLLECTIONS MENU
    ////// MOBILE MENU
*/

/*
    ////// GLOBALS
*/
nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li { display: inline-block; }
}



/*
    ////// MAIN MENU
*/
.main-menu{

    ul{
        @include flex-container;
        height: auto;
        justify-content: center;
        padding: 10px;
        margin: 0 auto;

        li{
            text-align: center;
            margin: 0 15px;
            text-transform: uppercase;
            @include font-size(1.2);
            font-weight: $bold;

            a{
                @include color-link($color-medium-gray);
                border-bottom: 1px solid transparent;
                padding: 5px;
                display: block;

                &:hover{
                    border-bottom: 1px solid $color-medium-gray;
                }
            }
        }
    }
}

/*
    ////// FOOTER MENU
*/
.footer{
    .main-menu{
        ul{
            li{
                @include font-size(1.4);
                text-transform: uppercase;

                a{
                    @include color-link($color-main);

                    &:hover{
                        border-bottom: 1px solid $color-main;
                    }
                }
            }
        }
    }
}


/*
    ////// SECTIONS MENU
*/
.sections-menu{
    .first-level{
        @include flex-container;
        justify-content: space-between;
    }
    li{
        @include font-size(1.4);
        text-transform: uppercase;
        font-weight: $bold;
        margin: 0;

        a{
            display: block;
            width: 100%;
            padding: 15px 15px 8px 15px;
            border-bottom: 2px solid transparent;

            &:hover{
                border-bottom: 2px solid $color-main;
            }

            @include bp(tablet){
                padding: 20px 0;
            }
        }
        &.selected{
            a{
                border-bottom: 2px solid $color-main;
            }
        }
    }
}

/*
    ////// COLLECTIONS MENU
*/
.show-educational-collections{
    background: url("../img/commons/arrow-down-menu.svg") no-repeat right center;
    border-bottom: 2px solid transparent;
    //padding-right: 15px;
    //padding-bottom: 8px;

    &.active{
        opacity: 0.5;
        background: url("../img/commons/arrow-up-menu.svg") no-repeat right center;
        border-bottom: 2px solid $color-main;
    }
}
.educational-collections-menu{
    display: none;
    position: absolute;
    background: white;
    padding: 40px 0;
    z-index: 10000;
    width: 100%;
    max-width: $wrapper;
    margin-top: 5px;

    @include bp(tablet){
        position: relative;
        padding: 20px 0;
    }
    .educational-collections-wrapper{
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 30px;
        align-items: start;

        .aside-actions{
            @include flex-fixed-element(20%);

            @include bp(tablet){
                display: none;
            }
        }

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    .second-level{
        @include flex-container;
        justify-content: flex-start;
        height: auto;

        li{
            border: none;
            @include flex-flexible-element;

            @include bp(tablet){
                @include flex-fixed-element(100%);
                @include font-size(1.2);
                font-weight: $bold;
            }
        }
        a{
            &:hover{
                border: none;
            }
        }
    }

    .third-level{
        padding: 10px 0 0 0;
        margin-left: 50px;

        li{
            display: block;
            @include font-size(1.2);
            line-height: 26px;
            line-height: 2.6rem;
            font-weight: $bold;
            padding: 0 0 0 15px;

            a{
                padding: 0;
            }
        }

        @include bp(tablet){
            padding: 10px 0;
        }
    }
    .educational-collection-name{
        @include font-size(1.5);
        font-weight: $bold;
        padding: 7px 0 7px 50px;
        width: 100%;
        display: block;
        border-radius: 100px;
        border: none;
        @extend %main-transition;

        a{
            border: none;
            padding: 5px;

            &:hover{
                border: none;
            }
        }
    }
    .aside-actions{
        .button_primary{
            @include font-size(1.4);
            padding: 20px 25px;
        }
    }

     .header.sticky &{
         left: 50%;
         transform: translateX(-50%);
         max-width: none;

         .educational-collections-wrapper{
             max-width: $wrapper;
             margin: 0 auto;
         }
     }
}
.sections-menu{
    &.reduced-menu{
        .educational-collections-menu{
            .second-level{
                li{
                    @include flex-fixed-element(31%);
                    margin: 0 0 15px 0;
                }
            }
            .third-level{
                display: none;
            }
        }
    }
}


/*
    ////// MOBILE MENU
*/
.mobile-menu{
    display: none;
    position: fixed;
    z-index: 10000;
    background: white;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 15px 0;
    overflow-y: auto;

    .sections-menu{
        padding: 15px;

        .first-level{
            li{
                @include flex-fixed-element(100%);
            }
        }
    }
    .main-menu{

        li{
            @include flex-fixed-element(100%);
            text-align: left;

            a{
                padding: 10px 5px;
            }
        }
    }
    .social-links{
        padding: 25px 15px;
    }
}
.close-menu{
    display: block;
    padding: 10px;
    height: 30px;
    width: 100%;
    border: none;
}