/********************************************************************************* CART */

/*  index

    ////// CART
    ////// CART FEEDBACK
*/

/*
    ////// CART
*/
.cart{
    h1{
        background: $color-secondary;
        padding: 10px 20px;
        color: white;
        font-weight: $bold;
        @include font-size(2.6);
        @include flex-container;
        align-items: center;

        .page-title{
            @include bp(mobile){
                @include flex-fixed-element(100%);
            }
        }
        .cart-id{
            @include font-size(2);
            text-align: right;

            @include bp(mobile){
                @include flex-fixed-element(100%);
                text-align: left;
            }
        }
    }
    table{
        text-align: center;
    }
    th{
        padding: 5px;

        &:nth-child(1){
            text-align: left;
        }
    }
    td{
        border: none;
        vertical-align: middle;
    }
    tbody tr{
        &:nth-child(odd){
            background: $color-beige;
        }
        &:nth-child(even){
            background: darken($color-beige,5%);
        }
        &:hover{
            background: lighten($color-secondary,40%);
        }
    }
    &.empty-cart{
        .cart-footer-container{
            display: none;
        }
    }
}
.cart-product{
    text-align: left;
}
.cart-image-thumbnail{
    width: 70px;
    height: 70px;
    border: 1px solid darken($color-light-gray,10%);
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.cart-product-name{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}
.delete-product{
    background: url("../img/icon-bin.svg") no-repeat center center;
    border: none;
    width: 30px;
    height: 30px;
}
.total-cart{
    background: lighten($color-secondary,30%);
    padding: 20px;

    p{
        font-weight: $bold;
        @include font-size(2.4);
        margin: 0;
        text-align: right;
    }
}

.cart-comments{
    margin: 30px 0;

    label{
        font-weight: $bold;
    }
    textarea{
        @include bp(superdesktop){
            max-width: 700px;
        }
    }
}
.no-order{
    background: url("../img/icon-empty-cart.svg") no-repeat center top;
    text-align: center;
    padding: 100px 10px 10px 10px;
    margin: 30px 0;

    h2{
        margin: 0;
    }

    .no-products-message{
        color: $color-red;
    }
}
body.hidden-price{
    .price-content, .total-cart{
        display: none;
    }
}


/*
    ////// CART FEEDBACK
*/
.cart-feedback{
    text-align: center;
    min-height: 900px;
    min-height: 70vh;
    margin: 30px 0;

    h1{
        background: url("../img/icon-tick-big.svg") no-repeat center top;
        padding-top: 110px;
        @include font-size(2);
        font-weight: $bold;
        margin: 0;
        color: $color-green;
        text-align: center;
        display: block;
    }

    .button_primary{
        margin: 30px 0;
    }
}