/********************************************************************************* ACCESS */

/*  index

    ////// LOGIN
    ////// RECOVER PASSWORD
*/

/*
    ////// LOGIN
*/
.login, .recover-password{
    max-width: 400px;
    margin: 50px auto;
    min-height: 900px;
    min-height: 60vh;

    .forgotpassword{
        @include font-size(1.4);
        margin: 5px 0;
        text-align: right;

        a{
            @include color-link($color-secondary);
            text-decoration: underline;
        }
    }
    label{
        font-weight: $bold;
    }
    .error-message{
        @include font-size(1.4);
        color: $color-red;
        font-weight: $bold;
    }
    .success-message{
        @include font-size(1.4);
        color: $color-green;
        font-weight: $bold;
    }
}