/********************************************************************************* ERROR PAGES */

/*  index

    ////// COMMONS
    ////// 404
    ////// 403

*/

/*
    ////// COMMONS
*/
.page-error{
    text-align: center;
    margin: 40px auto;
    max-width: $wrapper;

}
.help-contact{
    background: darken($color-beige,10%);
    padding: 20px;
    margin: 30px 0;
}
a{
    @include color-link($color-secondary);

    &:hover{
        text-decoration: underline;
    }
}

.errordefault{
    background: url("../img/icon-alert.svg") no-repeat center top;
    padding-top: 100px;
}

/*
    ////// 404
*/
.error404{
   background: url("../img/icon-not-found.svg") no-repeat center top;
    padding-top: 100px;
}

/*
    ////// 403
*/
.error403{
    background: url("../img/icon-access-big.svg") no-repeat center top;
    padding-top: 100px;
}