/********************************************************************************* MODALS */

/*  index

    ////// COMMONS
    ////// ACCESS MODAL
*/

/*
    ////// COMMONS
*/
.main-modal{
    display: none;
    //position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: map-get($zindex, modals);
    background: rgba($color-main,0.9);
}
.main-modal-wrapper{
    background: white;
    @include flex-container;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 50%;
    max-width: $wrapper;
    margin: 0 auto;
    padding: 20px 40px 40px 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    @include bp(tablet){
        width: 100%;
        padding: 20px 20px 40px 20px;
    }
    @include bp(mobile){
        display: block;
        height: 100vh;
        overflow: scroll;
        padding: 20px 10px 40px 10px;
    }
}
.main-modal-header{
    text-align: right;
    padding: 15px 20px;
    width: 100%;
}
.main-modal-close{
    display: block;
    height: 30px;
    position: relative;
    z-index: 100000;
}
.main-modal-content{
    margin: 0 auto;
    padding: 300px 0 0 0;
    width: 100%;

    @supports ( display: flex ) {
        padding: 0;
    }
    @media screen and (max-width: $wrapper){
        padding-left: 20px;
        padding-right: 20px;
    }

    h1{
        @include font-size(2.3);
        text-transform: uppercase;
        font-weight: $bold;
        margin: 0 0 10px 0;
    }
}
.main-modal-title{
    margin: 0 0 40px 0;
    text-align: center;
}
.main-modal-footer{
    text-align: center;
}


/*
    ////// ACCESS MODAL
*/
.modal-access{

    .main-modal-wrapper{
        max-width: 1000px;
        margin: 0 auto;
    }
    .access-options{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;

        .login-option{
            background: white;
            padding: 10px 50px;
        }
        .register-option{
            background: white;
            padding: 10px 50px;
        }
    }
    .register-advantages{
        margin: 30px 0;

        li{
            @include font-size(1.4);
            margin: 0 0 20px 0;
            background: url("../img/commons/arrow-right.svg") no-repeat left 4px;
            padding-left: 15px;
        }
    }
    .forgotpassword{
        @include font-size(1.4);
        font-weight: $bold;
        margin: -20px 0 20px 0;

        a{
            display: block;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}