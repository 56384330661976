/********************************************************************************* COMMONS */

/*  index

    ////// GLOBALS
    ////// FEEDBACK STYLES
    ////// SOCIAL LINKS
    ////// BREADCRUMB
    ////// PAGES ELEMENTS
*/



/*
    ////// GLOBAL SYTLES
*/
html{
    scroll-behavior: smooth;
}
body {
    @extend %font-family-main;
	@include font-size(1.6);
	color: $color-main;
    font-weight: $regular;
    padding-top: 65px;

    &.frozen{
        overflow: hidden;
    }

    @include bp(tablet){
        padding-top: 100px;
    }
    @include bp(mobile){
        padding-top: 85px;
    }
}
ul{
	li{
		list-style-type: none;
	}
}
ol{
	li{
		list-style-type: decimal;
		list-style-position: inside;
	}
}
a{
	cursor: pointer;
    
    &:link{ color: $color-main;}
    &:hover{} 
    &:visited{color: $color-main;}
    &:active{color: $color-main;}
}
img{
    max-width: 100%;
}

/*
    ////// FEEDBACK STYLES
*/
.feedback{
    margin: 10px 0;
    text-align: left;

    &.success{
        background: url("../img/icon-tick.svg") no-repeat left 5px;
        padding: 5px 5px 5px 30px;
        color: $color-green;
        font-weight: $bold;
    }
    &.error{
        background: url("../img/icon-alert.svg") no-repeat left 5px;
        background-size: 35px;
        padding: 5px 5px 5px 30px;
        color: $color-red;
        font-weight: $bold;
        @include font-size(1.4);
    }
    p{
        @include font-size(1.4);
    }
}
.form-errors{
    li{
        color: $color-red;
        font-weight: $bold;
        @include font-size(1.4);
    }
}

/*
    ////// SOCIAL LINKS
*/
.social-links{
    @include flex-container;
    justify-content: flex-start;
    height: auto;

    li{
        margin: 0 30px 0 0;
    }
    a{
        width: 20px;
        height: 20px;
        display: block;
        @extend %main-transition;
    }
    .facebook{
        a{
            background: url("../img/commons/social-icon-facebook.svg") no-repeat center center;
        }
    }
    .twitter{
        a{
            background: url("../img/commons/social-icon-twitter.svg") no-repeat center center;
        }
    }
    .instagram{
        a{
            background: url("../img/commons/social-icon-instagram.svg") no-repeat center center;
        }
    }
}
.social-share{
    @include flex-container;
    justify-content: space-around;
    background: white;
    padding: 12px 30px;

    a{
        width: 30px;
        height: 30px;
        display: inline-block;

        &:hover{
            opacity: 0.3;
        }
    }
}

/*
    ////// BREADCRUMB
*/
.breadcrumb{
    @include font-size(1.2);
    font-weight: $regular;
    margin: 0 0 20px 0;
    font-style: italic;

    .breadcrumb-separator{
        padding: 0;
        margin: 0;
    }
    .current-page{
        font-weight: $bold;
    }

    a, span{
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
    }
    a{
        @include color-link($color-secondary);
        &:hover, &:active{
            text-decoration: underline;
        }
    }
    .breadcrumb-home{
        background: url("../img/icon-home.svg") no-repeat left center;
        width: 20px;
        height: 20px;
    }
    li{
        margin: 0;
        @include font-size(1.2);
        display: inline-block;
        vertical-align: middle;
    }
}


/*
    ////// PAGES ELEMENTS
*/
.featherlight-content{
    .featherlight-previous, .featherlight-next{
        & > span{
            display: none;
        }
        &:hover > span{
            display: none;
        }
    }
}

.alert{
    padding: 10px 10px 10px 80px;
    
    p{
        margin: 0;

        @include bp(desktop){
            @include font-size(1.4);
        }
    }

    &.danger{
        background: url("../img/icon-alert.svg") no-repeat 10px center $color-red;
        color: #fff;

        @include bp(mobile) {
            background: url("../img/icon-alert.svg") no-repeat center top 5px $color-red;
        }
    }
    &.info{
        background: url("../img/icon-alert.svg") no-repeat 10px center darken($color-secondary,15%);
        color: #fff;

        @include bp(mobile) {
            background: url("../img/icon-alert.svg") no-repeat center top 5px darken($color-secondary,15%);
        }
    }

    @include bp(mobile){
        background-size: 50px;
        padding: 50px 10px 20px 10px;
        text-align: center;
    }
}