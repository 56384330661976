/********************************************************************************* COLORS */

/*  index

    ////// ESSENTIALS
    ////// CUSTOM
    ////// BUTTONS
    ////// TABLES
*/

/*
    ////// ESSENTIALS
*/
$color-main: 					#565556;
$color-secondary: 				#42B4D5;
$color-green:                   #63B40A;
$color-red: 					#FF0000;
$color-dark-gray:               #565656;
$color-medium-gray:             #7B828B;
$color-light-gray:              #FAF9F9;
$color-super-light-gray:        #F6F6F6;

/*
    ////// CUSTOM
*/
$color-beige:                   #FAF9F9;
$color-orange:                  #FF7106;


/*
    ////// BUTTONS
*/
$color-main-button:             #004FA3;
$color-main-button-text:        white;
$color-main-button-text-hover:  white;

/*
    ////// TABLES
*/
$color-table-header: 			#FBFBFB;