/********************************************************************************* PRODUCTS */

/*  index

    ////// SEARCH BOX
    ////// PRODUCT LIST
    ////// ADD TO CART
    ////// PRODUCT DETAIL
*/

/*
    ////// SEARCH BOX
*/
.search-box{
    background: $color-secondary;
    padding: 30px 10px;
    border-radius: 4px;

    .main-form{
        @include flex-container;
        justify-content: center;
        align-items: flex-start;

        label{
            color: white;
        }
        fieldset{
            margin: 0 5px;

            &.search-box-input{
                @include flex-fixed-element(310px);

                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    @include font-size(1.4);
                    font-style: italic;
                }
                ::-moz-placeholder { /* Firefox 19+ */
                    @include font-size(1.4);
                    font-style: italic;
                }
                :-ms-input-placeholder { /* IE 10+ */
                    @include font-size(1.4);
                    font-style: italic;
                }
                :-moz-placeholder { /* Firefox 18- */
                    @include font-size(1.4);
                    font-style: italic;
                }

                .filter-item{
                    border-radius: 100px;
                    min-width: 200px;
                }

                @media screen and (max-width: 1000px){
                    @include flex-fixed-element(300px);
                }
                @include bp(tablet){
                    @include flex-fixed-element(47%);
                    margin-bottom: 7px;
                }
                @media screen and (max-width: 550px){
                    @include flex-fixed-element(100%);
                }
            }
            &.categories-select{
                @include flex-fixed-element(200px);

                @media screen and (max-width: 1000px){
                    @include flex-fixed-element(170px);
                }
                @include bp(tablet){
                    @include flex-fixed-element(47%);
                    margin-bottom: 7px;
                }
                @media screen and (max-width: 550px){
                    @include flex-fixed-element(100%);
                }
            }
            &.subcategories-select{
                @media screen and (max-width: 1000px){
                    @include flex-fixed-element(170px);
                }
                @include bp(tablet){
                    @include flex-fixed-element(47%);
                    margin-bottom: 7px;
                }
                @media screen and (max-width: 550px){
                    @include flex-fixed-element(100%);
                }
            }
            &.brand-select{
                @media screen and (max-width: 1000px){
                    @include flex-fixed-element(170px);
                }
                @include bp(tablet){
                    @include flex-fixed-element(47%);
                    margin-bottom: 7px;
                }
                @media screen and (max-width: 550px){
                    @include flex-fixed-element(100%);
                }
            }
            &.offer-products-option{
                @include flex-fixed-element(210px);
                pointer-events: none;

                label{
                    cursor: not-allowed;
                    opacity: 0.4;
                }

                &.has-outlet{
                    pointer-events: auto;

                    label{
                        cursor: pointer;
                        opacity: 1;
                    }
                }
                @include bp(tablet){
                    @include flex-fixed-element(47%);
                    margin-bottom: 7px;
                }
                @media screen and (max-width: 550px){
                    @include flex-fixed-element(100%);
                }
            }
            @media screen and (max-width: 500px){
                .style-select select{
                    padding: 5px 30px 5px 10px;
                }
            }
        }
        .filter-separator{
            display: none;

            @media screen and (max-width: 1500px){
                display: block;
                width: 100%;
                padding-top: 10px;
            }
            @media screen and (max-width: 550px){
                display: none;
            }
        }
        @include bp(mobile){
            justify-content: stretch;
        }
    }
    .button_search{
        margin-left: 30px;

        @include bp(tablet){
            @include flex-fixed-element(47%);
            margin-bottom: 7px;
            margin-left: 0;
        }
        @media screen and (max-width: 550px){
            @include flex-fixed-element(100%);
        }
    }
    .reset-filters{
        background: none;
        border: none;
        padding: 10px 20px;
        @include font-size(1.4);
        text-decoration: underline;

        @include bp(mobile){
            @include flex-fixed-element(100%);
            padding: 10px 30px 0 10px;
            text-align: right;
        }
    }
    @media screen and (max-width: 1340px){
        padding: 30px 20px;
    }
    @include bp(desktop){
        padding: 20px 10px;
    }
}

/*
    ////// PRODUCT LIST
*/
.products-list-wrapper{
    margin: 30px 0;
}
.product-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;

    @media screen and (min-width: 1700px){
        grid-template-columns: repeat(4, 1fr);
    }
    @include bp(desktop){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: 1fr;
    }
    .product-list-item{
        background: $color-light-gray;
        border: 1px solid darken($color-light-gray,10%);
        padding: 15px;
        @extend %main-transition;

        &:hover{
            border: 1px solid $color-secondary;
            background: darken($color-light-gray,3%);

            img{
                transform: scale(1.3);
            }
        }
    }
    .product-main-info{
        @include flex-container;
        align-items: flex-start;
    }
    .product-thumbnail{
        @include flex-fixed-element(30%);
        border: 1px solid $color-main;
        background: white;
        overflow: hidden;
        max-height: 150px;

        a{
            display: block;
            height: 100%;
            width: 100%;
            max-height: 150px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
            @extend %main-transition;
        }
        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
            height: 150px;
        }
    }
    .product-summary{
        @include flex-fixed-element(65%);
        min-height: 155px;

        @include bp(mobile){
            @include flex-fixed-element(100%);
            min-height: 1px;
        }
    }
    p{
        @include font-size(1.4);
        margin: 0;
    }
    h2{
        @include font-size(2);
        line-height: 22px;
        line-height: 2.2rem;
        margin: 0 0 5px 0;
        font-weight: $bold;

        a{
            @include color-link($color-main);
        }
    }
    .reference-brand{
        margin: 7px 0;
    }
    .product-short-description{
        color: lighten($color-medium-gray,10%);
        line-height: 18px;
        line-height: 1.8rem;
        margin: 0 0 10px 0;
    }
    .categories{
        line-height: 18px;
        line-height: 1.8rem;

        a{
            text-decoration: underline;
        }

    }
}
.aside-product-info{
    //border-top: 1px dotted $color-secondary;
    margin: 10px 0 0 0;
    font-style: italic;

    &.with-info, &.with-multiplicity{
        padding: 10px 0 0 25px;
        background: url("../img/icon-info.svg") no-repeat left 7px;
    }
    p{
        margin: 0;
        @include font-size(1.2);
    }
}
.no-products{
    padding: 30px;
    margin: 30px 0;
    text-align: center;

    p{
        margin: 0;
    }
    .error-message{
        color: $color-red;
        font-weight: $bold;
        @include font-size(1.8);
    }
    a{
        text-decoration: underline;
    }
    .button_primary{
        text-decoration: none;
        margin: 30px 0;
    }
}
.product-stock-info{

    .stock-available{
        color: $color-green;
    }
    .stock-limited{
        color: $color-orange;
    }
    .no-stock{
        color: $color-red;
    }
}

/*
    ////// ADD TO CART
*/
.add-to-cart{
    @include flex-fixed-element(100%);
    @include flex-container;
    height: auto;
    align-items: center;
    border-top: 2px solid darken($color-beige,10%);
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;

    .price{
        background: url("../img/icon-label.svg") no-repeat left 5px;
        padding-left: 30px;
        //margin-bottom: 10px;

        .original-price{
            @include font-size(2);
            font-weight: $bold;
        }

        &.with-offer{
            .original-price{
                @include font-size(1.6);
                font-weight: $regular;
                color: $color-red;
                text-decoration: line-through;
                background: url("../img/arrow-right-price.svg") no-repeat right center;
                padding-right: 20px;
                margin-right: 5px;
            }
        }
        .offer-price{
            @include font-size(2);
            font-weight: $bold;
        }
    }

    .quantity, .button_primary{
        display: inline-block;
        vertical-align: middle;

        &:hover{
            text-decoration: none;
        }
    }
    input[type="submit"].button_primary{
        padding: 5px 20px;
        min-width: 110px;
        @extend %main-transition;

        &.added{
            background: $color-green;
        }
    }
    .add-to-cart-feedback{
        display: none;

        animation: fadeInOut;
        animation-duration: 2.5s;
        animation-timing-function: ease-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;

        color: $color-green;
    }
    .flying-cart{
        display: none;
        width: 30px;
        height: 30px;
        background: url("../img/flying-cart.svg") no-repeat center center;
        position: absolute;
        margin: -4px 0 0 -5px;
        z-index: 100000;


        animation: grow;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;

        @include bp(mobile){
            margin: 3px 0 0 -5px;
        }
    }
    .product-description &{
        border-top: none;
    }
    .gotologin{
        @extend .button;
        background: url("../img/icon-user.svg") no-repeat 10px center $color-secondary;
        padding-left: 40px;
        @include color-link(white);

        &:hover, &:active{
            background: url("../img/icon-user.svg") no-repeat 10px center darken($color-secondary,15%);
        }


        @include bp(mobile){
            display: block;
            margin-top: 10px;
        }
    }
}
.quantity {
    position: relative;
    width: 112px;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
        position: relative;
        z-index: 1000;
    }

    input {
        width: 45px;
        height: 32px;
        line-height: 1.65;
        display: block;
        margin: 0 auto;
        text-align: center;
        padding: 5px;
        @include font-size(1.4);

        &:focus {
            outline: 0;
        }
    }
    .quantity-nav {
        position: absolute;
        top: 0;
        left: 0;
        height: 34px;
        width: 100%;
    }

    .quantity-button {
        position: absolute;
        cursor: pointer;
        width: 34px;
        height: 32px;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        &.quantity-up {
            position: absolute;
            top: 0;
            right: 0;
            background: url("../img/icon-plus.svg") no-repeat center center;

        }
        &.quantity-down {
            position: absolute;
            left: 0;
            background: url("../img/icon-minus.svg") no-repeat center center;

        }
    }
}
body.hidden-price{
    .add-to-cart{

        .price{
            display: none;
        }
    }
    .product-list{
        .add-to-cart {
            justify-content: flex-end;
        }
    }
    .current-order{
        .cart-total-price{
            display: none;
        }
    }
}

/*
    ////// PRODUCT DETAIL
*/
.goback{
    background: url("../img/arrow-pager-left.svg") no-repeat left center;
    background-size: 20px;
    padding: 5px 0 5px 25px;
    border: none;
    box-shadow: none;
    @include font-size(1.4);
    margin: 0 0 10px 0;
}
.product-detail{
    h1{
        background: $color-secondary;
        color: white;
        @include font-size(2.6);
        line-height: 26px;
        line-height: 2.6rem;
        font-weight: $bold;
        margin: 0;
        padding: 10px;
    }
}
.product-identifier{
    background: $color-beige;
    padding: 10px;
    @include flex-container;
    height: auto;
    margin: 0 0 40px 0;

    .reference-brand{
        margin: 0;
    }
    .categories-list{

        li{
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            @include font-size(1.4);
        }
        a{
            display: block;
            background: lighten($color-medium-gray,15%);
            @include color-link(white);
            padding: 5px 10px;
            border-radius: 100px;
        }
    }
    @include bp(tablet){
        margin: 0 0 20px 0;
    }
}
.product-sheet{
    @include flex-container;
    height: auto;

    .add-to-cart{
        justify-content: flex-start;
        align-items: flex-start;

        .price{
            margin: 0 20px 0 0;
        }
    }
}
.product-detail-info{
    @include flex-fixed-element(60%);
    padding: 0 50px 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "files description description";
    grid-column-gap: 40px;

    .aside-product-info{
        border: none;
        margin: 0 0 30px 0;

        @include bp(tablet){
            p{
                @include font-size(1.2);
            }
        }
    }

    @include bp(desktop){
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        grid-template-areas:    "description"
        "files";
    }

    @include bp(desktop){
        @include flex-fixed-element(48%);
        padding: 0 30px 0 0;
    }
    @include bp(tablet){
        @include flex-fixed-element(100%);
        padding: 0;
    }
}
.product-files{
    grid-area: files;

    .product-images{
        margin: 0 0 20px 0;

        ul{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 10px;

            li{
                margin: 0;

                &:only-child{
                    grid-column: 1 / span 2;
                }
            }
            a{
                display: block;
                border: 1px solid $color-medium-gray;
                width: 100%;
                height: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }
        }
    }
}
.product-description{
    grid-area: description;

    @include bp(desktop){
        margin: 0 0 40px 0;
    }
}
.short-description{
    font-weight: $bold;
}
.product-attachments{
    margin: 20px 0;

    li{
        margin: 0;

        a{
            background: url("../img/icon-clip.svg") no-repeat left 6px;
            display: block;
            min-height: 20px;
            @include color-link($color-secondary);
            padding: 4px 5px 4px 16px;
        }
    }
}
.grouped-products{
    @include flex-fixed-element(35%);
    height: 900px;
    height: 90vh;
    overflow-y: auto;

    h2{
        @include font-size(1.8);
        font-weight: $bold;
        margin: 0;
        text-transform: uppercase;
    }

    .product-list {
        grid-template-columns: 1fr;
        grid-row-gap: 8px;

        .reference-brand{
            margin: 0;
        }
        .product-short-description{
            display: none;
        }
        .aside-product-info{
            p{
                @include font-size(1.2);
            }
        }
        .product-thumbnail{
            max-height: 120px;
        }

        @media screen and (min-width: 2200px){
            grid-template-columns: repeat(2, 1fr);
        }

    }

    @include bp(desktop){
        @include flex-fixed-element(48%);
    }
    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin-top: 30px;
    }
}
.add-to-cart-wrapper{
    margin: 40px 0 0 0;

    h2{
        border-bottom: 1px solid $color-secondary;
        margin: 0 0 10px 0;
        padding: 0;
        position: relative;
        @include font-size(1.8);
        color: $color-secondary;
        text-transform: uppercase;
        font-weight: $bold;

        &:after{
            content: url("../img/icon-cart-big.svg");
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.related-categories{
    background: darken($color-beige,10%);
    padding: 20px;
    margin: 40px 0 0 0;

    h2{
        @include font-size(1.8);
        font-weight: $bold;
        margin: 0 0 10px 0;
        text-transform: uppercase;
    }
}
.related-categories-list{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    li{
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }
    a{
        display: block;

        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .category-image{
        margin: 0 0 10px 0;
        overflow: hidden;
        height: 150px;
        border: 1px solid #fff;
        background: #fff;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            @extend %main-transition;
        }
    }

    @media screen and (min-width: 2200px){
        grid-template-columns: repeat(10, 1fr);
    }
    @include bp(tablet){
        grid-template-columns: repeat(6, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: repeat(3, 1fr);
    }
}

.featherlight-content{
    .featherlight-next{
        background: url("../img/arrow-pager-right.svg") no-repeat right center;

        &:hover{
            background: url("../img/arrow-pager-right.svg") no-repeat right center rgba(white,0.5);
        }
    }
    .featherlight-previous{
        background: url("../img/arrow-pager-left.svg") no-repeat left center;

        &:hover{
            background: url("../img/arrow-pager-left.svg") no-repeat left center rgba(white,0.5);
        }
    }
}
