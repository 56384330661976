/********************************************************************************* HEADER */

/*  index

    ////// HEADER
    ////// TOP ACTIONS
*/

/*
    ////// HEADER
*/
.header{
    padding: 20px;
    background: $color-main;
    border-bottom: 5px solid $color-secondary;
    color: white;
    @include flex-container;
    height: auto;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10000;

    @include bp(mobile){
        padding: 10px;
    }
}
.logo{

    @include bp(tablet){
        @include flex-fixed-element(100%);
        text-align: center;
    }
    @include bp(mobile){
        text-align: left;
    }
}

/*
    ////// TOP ACTIONS
*/
.top-actions{

    li{
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 10px;
        padding: 0 10px 0 0;
        border-right: 5px solid $color-secondary;
        @include font-size(1.4);

        &.all-products{
            text-transform: uppercase;
        }
        &.current-order{
            background: url("../img/icon-sheet.svg") no-repeat left center;
            padding-left: 30px;
            position: relative;

            a{
                display: inline-block;
            }
            .cart-total-price{
                margin-left: 20px;
                font-weight: $bold;
                min-width: 60px;
                display: inline-block;
                text-align: right;

                @include bp(mobile){
                    margin: 0;
                }
            }
            .total-products{
                position: absolute;
                top: -15px;
                left: 10px;
                background: $color-secondary;
                width: 20px;
                height: 20px;
                @include font-size(1.2);
                padding: 0;
                border-radius: 100px;
                text-align: center;
            }
            &.active{
                a{
                    color: $color-green;
                }
            }
        }
        &.my-account{
            background: url("../img/icon-user.svg") no-repeat left center;
            padding-left: 30px;

            a{
                display: inline-block;
            }
        }

        &:only-child{
            border: none;
        }
        @include bp(desktop){
            &.logout{
                position: absolute;
                top: 5px;
                right: 10px;
                z-index: 10000;
                border: none;

                a{
                    min-height: 20px;
                }
            }
            &.download-area{
                margin-right: 10px;
            }
        }
        @include bp(tablet){
            .top-action-text{
                display: none;
            }
        }
        @include bp(mobile){
            //display: block;
            //width: 31%;
            width: auto;
            border-right: none;
            margin: 0;
            padding: 7px 0;
            text-align: left;

            &.all-products{
                //border-bottom: 1px solid $color-secondary;
                //text-align: center;
                margin-right: 10px;

                a{
                    @include color-link($color-secondary);
                    font-weight: $bold;
                }
            }
            &.current-order{
                margin-right: 10px;
            }

            &:last-child{
                border-bottom: none;
            }
        }
        @include bp(minimobile){
            &.all-products{
                margin-right: 5px;
                text-transform: none;
            }
            &.current-order{
                margin-right: 5px;
            }
        }
    }
    a{
        @include color-link(white);
        @extend %main-transition;

        &:hover{
            text-decoration: underline;
        }
    }
    @include bp(tablet){
        @include flex-fixed-element(100%);
        //text-align: center;
        //margin: 10px 0 0 0;
    }
}
.logout{
    a{
        background: url("../img/arrow-right-logout.svg") no-repeat right center;
        padding: 5px 30px 5px 5px;
        display: block;
    }
}
.hide-price{
    input[type="checkbox"]{
        opacity: 0;
        position: absolute;
        z-index: 1;

        & + label{
            position: relative;
            z-index: 10;
            background: url("../img/toggle-switcher-off.svg") no-repeat center center;
            width: 70px;
            height: 24px;
            display: block;
            @extend %main-transition;
            max-width: none;
        }
        &:checked + label{
            background: url("../img/toggle-switcher-on.svg") no-repeat center center;
            width: 70px;
            height: 24px;
            display: block;
        }
    }
    @include bp(tablet){
        position: absolute;
        top: 10px;
        border: none;
        right: 90px;
        margin: 0;
    }
    @include bp(mobile){
        top: 4px;
        right: 60px;
    }
}

.show-price-confirm-alert{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: rgba($color-main,0.85);

    .show-price-confirm-alert_content{
        max-width: 500px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border: 1px solid $color-light-gray;
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        text-align: center;
        color: $color-main;

        @include bp(superdesktop){
            margin-top: -25vh;
        }

        @include bp(tablet){
            min-width: 90%;
        }
    }
    h2{
        @include font-size(1.8);
        font-weight: $bold;
    }
    ul{
        @include flex-container;
        justify-content: center;

        li{
            margin: 0 10px;
            @include font-size(1.2);

            @include bp(mobile){
                @include flex-fixed-element(100%);
                margin: 0 0 10px 0;
            }
        }
    }
    .accept{
        @extend .button;
        background: $color-secondary;
        @include color-link(#fff);

        @include bp(mobile){
            min-width: 90%;
        }
    }
    .decline{
        @extend .button;
        background: $color-medium-gray;
        @include color-link(#fff);

        @include bp(mobile){
            min-width: 90%;
        }
    }
}