/********************************************************************************* FOOTER */

/*  index

    ////// MAIN FOOTER
    ////// SECONDARY FOOTER
*/


/*
    ////// MAIN FOOTER
*/
.footer{
    background: $color-main;
    @include flex-container;
    height: auto;
    color: white;
    padding: 20px;
    margin: 30px 0 0 0;

    p{
        margin: 0;
        @include font-size(1.4);
    }
    a{
        @include color-link(white);
        text-decoration: underline;
    }
}
